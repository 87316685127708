import { Component, OnInit } from '@angular/core';
import { TrainerDashboardService } from '../../service/trainer-dashboard.service';
import { TrainerDashboardPipe } from '../../pipe/trainer-dashboard.pipe';
import { TrainerDashboard } from '../../model/dashboard';
import { Card } from '../../model/card';
import { InterviewStatus } from '../../../../../static/constants/interview-status/interview-status.enum';
import { Router } from '@angular/router';
import { InterviewFilterService } from '../../../../interview/interview-filter.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-trainer-dashboard',
  templateUrl: './trainer-dashboard.component.html',
  styleUrls: ['./trainer-dashboard.component.scss']
})
export class TrainerDashboardComponent implements OnInit {

  cards: Card[];

  constructor(
    private service: TrainerDashboardService,
    private pipe: TrainerDashboardPipe,
    private datePipe: DatePipe,
    private interviewFilterService: InterviewFilterService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.service.getData().subscribe(
      (value: TrainerDashboard) => this.cards = this.pipe.transform(value),
    );
  }

  click(index: number): void {
    switch (index) {
      case 0:
        const dateStringToday = this.datePipe.transform(new Date(), 'yyyy-MM-dd') as string;
        this.interviewFilterService.filterByStatus = InterviewStatus.SCHEDULED;
        this.interviewFilterService.filterByStartDate = dateStringToday;
        this.interviewFilterService.filterByEndDate = dateStringToday;
        break;
      case 1:
        this.interviewFilterService.filterByStatus = InterviewStatus.SCHEDULED;
        this.interviewFilterService.filterByStartDate = null;
        this.interviewFilterService.filterByEndDate = null;
        break;
      case 2:
        this.interviewFilterService.filterByStatus = InterviewStatus.PENDING_LOG;
        this.interviewFilterService.filterByStartDate = null;
        this.interviewFilterService.filterByEndDate = null;
        break;
      default: return;
    }
    this.router.navigateByUrl('/trainings');
  }
}
