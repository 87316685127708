import { TrainingCard } from "./training-card";

export enum TrainingType {
  STUDENTS_YOUNG = 'Training for students - Ages 9 —14 years old',
  STUDENTS_OLD = 'Training for students - Ages 15+ years old',
  VOLUNTEERS_PART1 = 'Training for volunteers part 1',
  VOLUNTEERS_PART2 = 'Training for volunteers part 2',
}

export interface Training {
  id: number;
  startDate: number;
  endDate: number;
  slots: number;
  status: TrainingStatus;
  supervisorId: number;
  supervisorFirstName: string;
  supervisorLastName: string;
  type: TrainingType;
  typeFullName: string;
  participants: SessionParticipantCard[];
}

export interface SessionParticipantCard {
  firstName: string;
  lastName: string;
  email: string;
  role: number;
}

export enum TrainingStatus {
  SCHEDULED = 100,
  CANCELLED = 200,
  MISSED_BY_TRAINER,
  COMPLETED = 300,
  IN_PROGRESS = 400,
}

export interface TrainingsDTO {
  trainings: TrainingCard[];
  length: number;
}