import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { NotifierService } from "angular-notifier";
import { interval, startWith, map, switchMap, filter, Subject, takeUntil } from "rxjs";
import { UserRole } from "../../../static/constants/user-role/user-role.enum";
import { UserInfoService } from "../../../static/login/service/user-info.service";
import { ZoomService } from '@static/zoom/zoom.service';
import { ConfirmWindowComponent } from "../../matching/component/warning-window/confirm-window.component";
import { Clipboard } from '@angular/cdk/clipboard';
import { Training, TrainingStatus } from "../../availability-training/model/training";
import { TrainingService } from "../training.service";
import { Trainer } from "../../availability-training/model/trainer";
import { FormControl, Validators } from "@angular/forms";
import { environment } from "../../../../environments/environment";


@UntilDestroy()
@Component({
  selector: 'app-training-overview',
  templateUrl: './training-overview.component.html',
  styleUrls: ['./training-overview.component.scss']
})
export class TrainingOverviewComponent implements OnInit {
  role: UserRole;
  id: number;
  startDate: number;
  training: Training;
  trainingLinkUrl: string;
  isTrainingDisabled$ = interval(1000).pipe(
    startWith(true),
    map(this.isStartTrainingDisabled.bind(this))
  );
  copyButtonText = 'Copy link';
  trainers: Trainer[] = [];

  supervisorId = new FormControl(null, Validators.required);

  TrainingStatus = TrainingStatus;
  UserRole = UserRole;

  private destroy$ = new Subject<void>();

  constructor(
    private trainingService: TrainingService,
    private route: ActivatedRoute,
    private zoomService: ZoomService,
    private clipboard: Clipboard,
    private notifier: NotifierService,
    private router: Router,
    private userInfoService: UserInfoService,
    private dialog: MatDialog
  ) {
    this.role = this.userInfoService.getRole();
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = +params.id;
      this.trainingService.getTraining(this.id)
        .pipe(
          untilDestroyed(this)
        )
        .subscribe((training: Training) => {
          this.training = training;
          this.supervisorId.setValue(this.training.supervisorId);
        });
    });

    this.getTrainers();
  }

  isStartTrainingDisabled() {
    // if (!this.interview?.date) {
    //   return true;
    // }
    // const date = this.interview.date * 1000;
    // const min = moment(date).subtract(3, 'm');
    // const max = moment(date).add(5, 'm');
    // return !moment().isBetween(min, max);
  }

  startTraining(): void {
    if (this.training.status === TrainingStatus.SCHEDULED) {
      const id = this.id;
      const date = this.training.startDate;
      const duration = (this.training.endDate - this.training.startDate) / 60;
      const state = encodeURIComponent(JSON.stringify({ id, date, duration }));
      const url = `${environment.videoServiceUrl}/start-training?state=${state}`;
      this.trainingLinkUrl = url;
      this.trainingService.startTraining(url);
    }
  }

  cancelTraining(): void {
    const dialogRef = this.dialog.open(ConfirmWindowComponent, {
      disableClose: true,
      autoFocus: true,
      data: {
        title: 'Are you sure you want to cancel?',
        confirmButtonName: 'Cancel',
        cancelButtonName: 'Back',
        width: '50vw',
      }
    });
    dialogRef.afterClosed().pipe(
      filter((isConfirmed: boolean) => isConfirmed),
      switchMap(() => this.trainingService.cancelTraining(this.id)),
      untilDestroyed(this)
    ).subscribe({
      next: () => {
        this.router.navigateByUrl('/trainings');
        this.notifier.notify('success', 'Training cancelled');
      },
      error: (error: HttpErrorResponse) => this.notifier.notify('error', error.error.message)
    });
  }

  changeTrainer(): void {
    const newTrainerId = this.supervisorId.value;
    this.trainingService.changeTrainer(this.id, newTrainerId).subscribe(() => {
      this.notifier.notify('success', 'Trainer saved')
    }, (error) => this.notifier.notify('error', 'Error occured'))
  }

  copyUrl(): void {
    this.clipboard.copy(this.trainingLinkUrl);
    this.copyButtonText = 'Link was copied';
  }

  private getTrainers(): void {
    this.trainingService
      .getTrainers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => (this.trainers = value));
  }
}
